import { AxiosInstance } from 'axios'
import { AxiosRes } from './index'
import { Translations } from '../hooks/useTranslation'

export const translationApi = (instance: AxiosInstance) => ({
    async getTranslations(culture: string, gameName: string): Promise<TranslationsRes> {
        const { data } = await instance.get<TranslationsRes>(
            `/api/Translation/translations?Culture=${culture}&GameName=${gameName}`,
            { baseURL: 'https://roundgamesapi.yogames.win' },
        )
        return data
    },
})

interface TranslationsRes extends AxiosRes {
    result: Translations
}